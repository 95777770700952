'use strict';
window.esign = window.esign || {};

esign.cacheSelectors = function() {
  esign.cache = {
    // general
    $body: $('body'),

    // overview
    $propertiesOverview: $('#properties-overview'),
    $regionContent: $('#region-content'),
    propertiesOverviewIsLoading: 0,
    $stayInformedTitle: $('#js-stay-informed-title'),
    filterTimeout: null,
    $filtersForm: $('.filters-form'),
  };
};

esign.init = function() {
  esign.ga();
  esign.cacheSelectors();

  // object fit polyfill
  objectFitImages('.cta__image img, .landscape-slideshow__img img, .thumbs-list__visual img, .overview-results__static-map-img img, .splash__visual img, .banner__visual img, .packages-list__visual img');

  esign.responsiveVideos();
  esign.formAjax();
  esign.menu();
  esign.modaal();
  // esign.sortResultDropdown();
  esign.scrollTo();
  esign.accordion();
  esign.slideshow();
  esign.ctaSlideshow();
  esign.lightGallery();

  // esign.select2();

  if (esign.cache.$propertiesOverview.length > 0) {
    esign.propertiesOverview();
  }

  esign.tableRowBlockLink();
  esign.ctaVisited();
  esign.calendar();
  esign.formsChoices();
  esign.rentalBooking();
  esign.referenceForm();
  esign.popover();
  // esign.openingHoursPopup();

  esign.gaDevelopment();
  esign.virtualPageView();
};

esign.createCookie = function(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = '; expires=' + date.toGMTString();
  } else var expires = '';
  document.cookie = name + '=' + value + expires + '; path=/';
}

esign.readCookie = function(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

esign.virtualPageView = function() {
  $('[data-ga-pageview]').click(function(e){
    $element = $(this);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'pageView',
      'pagePath': $element.data('ga-pageview'),
    });
    return true;
  });
};

esign.responsiveVideos = function() {
  $(
    'iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]'
  ).wrap('<div class="video-container"></div>');
};

// Ajax newsletter subscribe
esign.formAjax = function() {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr('disabled', 'disabled');

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if (data.errors === false) {
        $form.html(data.result);
        console.log(data.redirect);
        if(typeof data.redirect !== "undefined"){
          console.log('yes');
          window.location = data.redirect;
        }
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// Ga events
esign.ga = function() {
  // if (typeof ga === typeof undefined) {
    // window.ga = function() {
    //   var argumentsArray = [];
    //   for (var key in arguments) {
    //     if (arguments.hasOwnProperty(key)) {
    //       argumentsArray.push(arguments[key]);
    //     }
    //   }

    //   var msg =
    //     '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
    //   console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    // };
  // }

  $('.tracking').on('click', function () {
    var link = $(this);
    var txt = (link.data('title') !== 'undefined') ? link.data('ga-value') : link.attr('href').replace(/^https?\:\/\//i, '');
    ga('send', 'event', 'button', 'click', txt);
  });
};

esign.menu = function() {
  var lastScrollTop = 0;
  var $header = $('.page-header');

  $(window).scroll(function() {
    var st = window.pageYOffset || document.documentElement.scrollTop;

    if (st > lastScrollTop) {
      $header.addClass('page-header--fixed');
    }

    if(st < 50) {
      $header.removeClass('page-header--fixed');
    }

    lastScrollTop = st;
  });

  $('.lang__trigger').click(function(e) {
    e.preventDefault();

    $(this).next().toggle('fast');
  });
};

esign.modaal = function() {

  $('.modal-nav-trigger').modaal({
    background_scroll: false,
    overlay_opacity: 0.95,
    background: '#fff',
    fullscreen: true
  });

  $('.js-inline-modaal').modaal({
    overlay_opacity: 0.95,
    background: '#fff'
  });

  var hash = window.location.hash;
  if (hash.indexOf('#package-') === 0) {
    $('a[href="' + hash + '"]').first().trigger('click');
  }
};

esign.sortResultDropdown = function() {
  var sortDropdownActive = false;

  $(window).click(function() {
    sortDropdownActive = false;
    if (!sortDropdownActive) checkSort();
  });

  $('#sortResults').on('click', function(e) {
    e.stopPropagation();
    sortDropdownActive = !sortDropdownActive;
    checkSort();
  });

  function checkSort() {
    if (sortDropdownActive) {
      $('.offer-results__dropdown-content').css('display', 'block');
    } else {
      $('.offer-results__dropdown-content').css('display', 'none');
    }
  }
};

esign.scrollTo = function() {
  $('.js-scroll-to').click(function(e) {
    e.preventDefault();

    var $this = $(this),
      offset = $this.data('offset');

    if (offset == undefined) {
      offset = -120;
    }

    $(window).scrollTo($($this.attr('href')), 1000, {
      axis: 'y',
      offset: offset,
    });
  });
};

esign.slideshow = function() {
  $('.landscape-slideshow').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true
  });
};

esign.lightGallery = function() {

  $('.lightgallery').each(function() {
    var $this = $(this),
      galleryId = 1;

    if ($this.data('lg-id')) {
      galleryId = $this.data('lg-id');
    }

    $this.lightGallery({
      selector: 'a',
      galleryId: galleryId,
      download: false,
      thumbWidth: 90,
      thumbHeight: 90,
      // counter: false,
      thumbMargin: 10,
      thumbContHeight: 110,
      toogleThumb: false,
    });
  });

  var $thumbs = $('.thumbs-list a'),
    $heroSlideshow = $('.property__hero .landscape-slideshow');

  if ($heroSlideshow.length) {

    $(document.documentElement).keyup(function (e) {
      // slideshow keyboard handler
      if(e.target.nodeName === 'BODY' && $(window).scrollTop() < $(window).height()) {
        if(e.keyCode == 39) {
          $heroSlideshow.slick('slickNext');
        }

        if(e.keyCode == 37) {
            $heroSlideshow.slick('slickPrev');
        }
      }
    });

    $('.trigger-lightgallery').click(function(e) {
      e.preventDefault();
      $($thumbs.get($heroSlideshow.slick('slickCurrentSlide'))).trigger('click');
    });

    $heroSlideshow.find('.cycle-prev, .cycle-next').click(function(e) {
      var opts = $heroSlideshow.data('cycle.opts');
      var galleryIndex = opts.nextSlide;

      if ($(this).hasClass('cycle-prev')) {
        galleryIndex = opts.currSlide - 1;
      }

      if (galleryIndex < 0) {
        // take last item
        $($thumbs.get($thumbs.length - 1)).trigger('click');
      } else {
        $($thumbs.get(galleryIndex)).trigger('click');
      }
    });
  }

  // iframe & video
  $('.trigger-lightgallery-self').each(function() {
    $(this).lightGallery({
      selector: 'this',
      download: false,
      counter: false,
      zoom: false,
      iframeMaxWidth: '100%',
      videoMaxWidth: '1600px',
      youtubePlayerParams: {
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        controls: 1
      },
    });
  });
};

esign.select2 = function() {
  $('.js-select2').each(function() {
    var $this = $(this);
    var placeholder = $this.data('placeholder');

    $this.select2({
      placeholder: placeholder,
      allowClear: false,
    });
  });
};

esign.ctaSlideshow = function() {
  $('.cta__slider').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    lazyLoad: 'ondemand'
  });
};

/* overview filters */
esign.propertiesOverview = function() {
  esign.initOverview();

  window.addEventListener('popstate', function(e) {
    esign.requestOverview();
  });
};

esign.initOverview = function() {
  esign.cache.$filtersForm = $('.filters-form');
  var triggerSave = false;

  $('.js-show-filters').click(function(e) {
    e.preventDefault();
    $('.filters-form').toggle('fast');
    $(this).toggleClass('active');
  });

  // sort properties
  esign.cache.$propertiesOverview.on('change', '#filter-sort', function() {
    // edit hidden input
    var sortValue = $(this).val();
    esign.cache.$filtersForm.find('input[name="sort"]').val(sortValue);
    esign.cache.$filtersForm.submit();
  });

  // on filter change
  esign.cache.$propertiesOverview.on('change', '.filters-form input[type="checkbox"], .filters-form select', function () {
    clearTimeout(esign.cache.filterTimeout);
    esign.cache.filterTimeout = setTimeout(function () {
      esign.cache.$filtersForm.submit();
    }, 200);
  });

  esign.cache.$propertiesOverview.on('focus', '.filters-form input:not(.js-calendar-from):not(.js-calendar-to)', function () {
    clearTimeout(esign.cache.filterTimeout);
  });

  esign.cache.$propertiesOverview.on('blur', '.filters-form input:not(.js-calendar-from):not(.js-calendar-to)', function () {
    clearTimeout(esign.cache.filterTimeout);
    esign.cache.filterTimeout = setTimeout(function () {
      esign.cache.$filtersForm.submit();
    }, 200);
  });

  // remove filter
  esign.cache.$propertiesOverview.on('click', '.selected-filters__item', function() {
    var $this = $(this);
    var name = $(this).data('name');

    if (name === 'date') {
      $('.js-calendar-from').val('');

    } else {
      var $inputs = esign.cache.$propertiesOverview.find('input[name="' + name + '[]"]');
      $inputs.filter('[type="checkbox"]').attr('checked', false);
      $inputs.filter('[type="text"], [type="number"]').val('');
      esign.cache.$propertiesOverview.find('input[name="' + name +'"][type="hidden"]').val('');
    }

    $this.remove();
    esign.cache.$filtersForm.submit();
  });

  esign.cache.$filtersForm.submit(function(e) {
    if (!triggerSave) {
      e.preventDefault();

      // console.log('submit');

      var inputs = esign.cache.$filtersForm.serializeArray();
      var url = window.baseOverviewUrl;

      // check if projects
      if (esign.cache.$body.hasClass('projects-index')) {
        url = esign.buildProjectsOverviewUrl(inputs);
      } else {
        // properties
        url = esign.buildOverviewUrl(inputs);
      }

      history.pushState(null, null, url);
      esign.requestOverview();
    }
  });

  $('.js-trigger-save-search').click(function(e) {
    triggerSave = true;
    esign.cache.$filtersForm.find('input[name="save"]').val('1');
    esign.cache.$filtersForm.submit();
  });

  // pagination requests
  esign.cache.$propertiesOverview.on('click', '.pagination a', function(e) {
    e.preventDefault();
    var url = $(this).attr('href');
    history.pushState(null, null, url);
    esign.requestOverview();
    document.documentElement.scrollTop = 0;
  });
};

esign.requestOverview = function() {
  var url = window.location.href;
  if (url.indexOf('?') > 0) {
    url += '&ajax=1'
  } else {
    url += '?ajax=1';
  }

  var $overviewResults = esign.cache.$propertiesOverview.find('.overview-results, #overview-map, .projects-results');

  // console.log(url);

  // set loading counter
  esign.cache.propertiesOverviewIsLoading++;
  $overviewResults.addClass('js-is-loading');

  $.getJSON(url, {}, function(data) {
    esign.cache.propertiesOverviewIsLoading--;

    // check if last json request
    if (esign.cache.propertiesOverviewIsLoading === 0) {
      $overviewResults.removeClass('js-is-loading');

      // scroll to top
      $(window).scrollTop(0);

      // ga is loaded
      if (typeof ga === 'function') {
        // ga pageview
        var gaUrl = window.location.pathname + window.location.search;
        gaUrl = gaUrl.replace('/immo-eecke', '').replace('/public', '');
        ga('send', 'pageview', gaUrl);
      }

      // check if new content
      if (data.content) {
        esign.cache.$propertiesOverview
          .off('change', '#filter-sort')
          .off('change', '.filters-form input')
          .off('click', '.selected-filters__item')
          .off('click', '.pagination a');

        esign.cache.$propertiesOverview.html(data.content);

        if (typeof data.stayInformedTitleText !== 'undefined' && data.stayInformedTitleText !== '') {
          esign.cache.$stayInformedTitle.html(data.stayInformedTitleText);
        }

        esign.initOverview();
        esign.ctaSlideshow();
        esign.calendar();

        if (data.title) {
          document.title = data.title;
        }

        if (data.description) {
          $('meta[name=description]').attr('content', data.description);
        }

        if (data.regionContent) {
          esign.cache.$regionContent.html(data.regionContent);
        } else {
          esign.cache.$regionContent.empty();
        }

        // check if markers
        if (data.markers) {
          window.markers = data.markers;
          mapview.init();
        }
      }
    }
  });
};

esign.buildOverviewUrl = function(inputs) {
  var overviewUrl = window.baseOverviewUrl;

  // filter inputs
  var typesPath = [];
  var filterParams = [];

  // loop form inputs
  for (var i = 0; i < inputs.length; i++) {
    var input = inputs[i];
    var inputName = input.name;
    var inputValue = input.value;

    if (inputName === 'type[]') {
      // push value of type
      typesPath.push(inputValue);
    } else {
      // its a parameter
      // input is not an array
      if (inputName.indexOf('[]') === -1) {
        // exclude items which are empty
        if (inputValue === '') {
          continue;
        }
      } else {
        // lookup other values from array
        var result = $.grep(inputs, function(item) {
          return item.name === inputName && item.value !== '';
        });

        // exclude arrays which are empty
        if (result.length === 0) {
          continue;
        }
      }

      var param = inputName + '=' + inputValue;
      filterParams.push(param);
    }
  }

  // check types
  if (typesPath.length > 0) {
    overviewUrl += typesPath.join('+');
  } else {
    overviewUrl += 'alle'
  }

  // check filter params
  if (filterParams.length > 0) {
    overviewUrl += '?' + filterParams.join('&');
  }


  return overviewUrl;
};

esign.tableRowBlockLink = function() {
  // .table-row-link
  $('.table-row-link').click(function(e) {
    if ($(e.target).is("a,input")) {
      return;
    }

    var link = $(this).find('.table-row-link__target').attr('href');
    if (link) {
      window.location = link;
    }
  });
};

esign.ctaVisited = function() {
  localStorage.setItem('visited-' + window.location.pathname, true);

  var links = document.querySelectorAll('.cta__target');
  for (i=0; i<links.length; i++) {
    var link = links[i];
    if (link.host == window.location.host
    && localStorage.getItem('visited-' + link.pathname)) {
      link.dataset.visited = true;
    }
  }
};

esign.calendar = function() {
  var $calendarForm = $('.calendar-form');

  if ($calendarForm.length) {

    var $bookingBoxHolder = $('#booking-box-holder');
    if ($bookingBoxHolder.length && window.innerWidth < 768) {
      $bookingBoxHolder.appendTo('.property__header');
    }

    if (esign.cache.dates) {
      esign.initCalendarDates();

    } else {
      $calendarForm.addClass('js-is-loading');

      $.ajax({ url: window.periodsApi }).done(function (data) {
        if (data && data.dates) {
          esign.cache.dates = data.dates;
          esign.cache.unavailableDates = data.unavailable;
          esign.cache.unavailableEvenings = data.unavailableEvenings;
          esign.cache.unavailableMornings = data.unavailableMornings;

          $calendarForm.removeClass('js-is-loading');

          esign.initCalendarDates();
        }
      });
    }
  }

};

esign.initCalendarDates = function() {
  var dates = esign.cache.dates;
  var unavailableDates = esign.cache.unavailableDates;
  var unavailableEvenings = esign.cache.unavailableEvenings;
  var unavailableMornings = esign.cache.unavailableMornings;

  var $bookingBoxHolder = $('#booking-box-holder');
  var $calendarFromDate = $('.js-calendar-from');
  var fromDates = Object.keys(dates);
  var enabledDates = fromDates;

  var lang = 'nl';
  if (document.documentElement.lang === 'fr') {
    lang = 'fr';
  }

  var $fpContainer = $('.flatpickr-rContainer');
  var $warningMonday = $('<div class="flatpickr-warning"><p>' +
      window.warningMonday +
    '</p></div>');
  $warningMonday.hide();

  // var $warningToday = $('<div class="flatpickr-warning"><p>' +
  //     window.warningToday +
  //   '</p></div>');
  // $warningToday.hide();

  let hasGeneralWarning = false;

  if(window.warningGeneral) {
    var $warningGeneral = $('<div class="flatpickr-warning"><p>' + window.warningGeneral + '</p></div>');
    $($warningGeneral).show();
    hasGeneralWarning = true;
  }

  var $warningNextDates = $('<div class="flatpickr-warning"><p>' +
      window.warningNextDates +
    '</p></div>');
  $warningNextDates.hide();

  var $fpActions = $('<div class="flatpickr-actions">' +
    '<p><a href="#" class="fp-clear">' +
    '<svg class="icon icon-close"><use xlink:href="#icon-close" /></svg>' +
    window.fpClearBtn +
    '</a></p></div>');

  // Calculate months to show
  var showMonths = 1;
  if (window.innerWidth > 680) {
    showMonths = 2;
  }

  // datepicker
  var fp = $calendarFromDate.flatpickr({
    minDate: 'today',
    locale: lang,
    showMonths: showMonths,
    dateFormat: 'd-m-Y',
    disableMobile: true,
    mode: 'range',
    onChange: function(selectedDates, dateStr, instance) {
      var fromDate = selectedDates[0];

      // Set dates if from date is picked
      if (fromDate && selectedDates.length === 1) {
        var fromDateFormatted = esign.formatDate(fromDate);

        if (fromDateFormatted && dates[fromDateFormatted]) {
          // console.log('set dates');
          // console.log(dates[fromDateFormatted]);
          enabledDates = dates[fromDateFormatted];
          fp.redraw();
        }

        // If from date is Monday or Tuesday
        if (fromDate.getDay() === 1 || fromDate.getDay() === 2) {
          $warningMonday.show();
        } else {
          $warningMonday.hide();
        }

        // If from date is today
        // var todayFormatted = esign.formatDate(new Date());
        // if (fromDateFormatted === todayFormatted) {
        //   $warningToday.show();
        // } else {
        //   $warningToday.hide();
        // }
      }

      // If to date is picked && is not the same date
      if (selectedDates.length > 1 && selectedDates[0] !== selectedDates[1]) {
        var toDate = selectedDates[1];
        enabledDates = fromDates;
        fp.redraw();

        if (esign.cache.$filtersForm.length) {
          clearTimeout(esign.cache.filterTimeout);
          esign.cache.filterTimeout = setTimeout(function () {
            esign.cache.$filtersForm.submit();
          }, 1000);
        }

        if ($bookingBoxHolder.length && window.bookingBoxApi) {
          clearTimeout(esign.cache.filterTimeout);
          esign.cache.filterTimeout = setTimeout(function () {
            esign.bookingBoxCall($bookingBoxHolder, $calendarFromDate, fromDate, toDate);
          }, 500);
        }
      }
    },
    onDayCreate: function(dObj, dStr, fp, dayElem) {
      var date = dayElem.dateObj;
      var dateFormatted = esign.formatDate(date);

      if (unavailableDates && unavailableDates.indexOf(dateFormatted) >= 0) {
        dayElem.classList.add('flatpickr-day-booked');

      } else if (enabledDates && enabledDates.indexOf(dateFormatted) === -1) {
        // disable date
        // console.log(fromDates.indexOf(dateFormatted));

        dayElem.classList.add('flatpickr-day-disabled');
      }

      if (unavailableMornings && unavailableMornings.indexOf(dateFormatted) >= 0) {
        dayElem.classList.add('flatpickr-morning-booked');
      }

      if (unavailableEvenings && unavailableEvenings.indexOf(dateFormatted) >= 0) {
        dayElem.classList.add('flatpickr-evening-booked');
      }

    },
    onOpen: function() {
      $fpContainer = $('.flatpickr-rContainer');

      // clear timeout when datepicker is shown
      clearTimeout(esign.cache.filterTimeout);

      // show actions
      $fpContainer.append($warningMonday);
      // $fpContainer.append($warningToday);

      if (window.warningNextDates !== '') {
        $fpContainer.append($warningNextDates);
      }

      if (hasGeneralWarning) {
        $fpContainer.append($warningGeneral);
        $warningGeneral.show();
      }

      $fpContainer.append($fpActions);
    },
    onMonthChange: function(selectedDates, dateStr, instance) {
      // show warning next dates
      $warningNextDates.hide();

      // Book from April next year message
      var nextYear = new Date().getFullYear() + 1;
      //if ((instance.currentYear === nextYear && instance.currentMonth >= 0) || instance.currentYear > nextYear) {
      if (instance.currentYear == 2025 && instance.currentMonth >= 3){
        $warningNextDates.show();
      }
    } 
  });

  $fpActions.find('.fp-clear').on('click', function(e) {
    e.preventDefault();
    fp.clear();
    enabledDates = fromDates;
    fp.redraw();
    if ($warningMonday) {
      $warningMonday.hide();
    }
    // if ($warningToday) {
    //   $warningToday.hide();
    // }
  });

  // click on calendar icons
  $calendarFromDate.next('.icon').click(function(e) {
    e.preventDefault();
    $calendarFromDate.trigger('click').focus();
    fp.open();
  });

  // trigger calendar
  $('.js-flatpickr-show').click(function(e) {
    e.preventDefault();
    $calendarFromDate.focus();

    setTimeout(function(){
      fp.open();
    }, 0);
  });
};

esign.bookingBoxCall = function ($bookingBoxHolder, $calendarFromDate, fromDate, toDate) {
  if (toDate > fromDate && toDate !== fromDate) {
    $bookingBoxHolder.addClass('js-is-loading');
    $bookingBoxHolder.removeClass('js-has-error');

    var data = [
      { name: 'date', value: $calendarFromDate.val() },
    ];

    $.ajax({
      url: window.bookingBoxApi + '?' + $.param(data)
    }).done(function (data) {
      $bookingBoxHolder.removeClass('js-is-loading');

      if (data && data.bookingbox) {
        $bookingBoxHolder.html(data.bookingbox);

        esign.initCalendarDates();
      }
    });
  } else {
    $bookingBoxHolder.addClass('js-has-error');
  }
};

esign.formatDate = function(date) {
  var day = date.getDate();
  var dayFormatted = (day < 10 ? '0' : '') + day;
  var month = date.getMonth() + 1;
  var monthFormatted = (month < 10 ? '0' : '') + month;
  var year = date.getFullYear();

  return year + '-' + monthFormatted + '-' + dayFormatted;
};

esign.formsChoices = function() {
  $('.js-choice').change(function(e) {
    var $this = $(this);
    var show = $this.is(':checked');
    var $target = $('#choice-' + $this.attr('name'));

    if ($this.is(':radio')) {
      // hide all with class name
      $('.js-choice-' + $this.attr('name').replace('_', '-')).hide('fast');
      // target is id
      $target = $('#choice-' + $this.attr('id'));
    }

    if (show) {
      $target.show('fast');
    } else {
      $target.hide('fast');
    }
  });
};

esign.rentalBooking = function() {
  if ($('body').hasClass('rental-book')) {
    var $bookingOptionsHolder = $('.js-booking-choices-holder');
    var $bookingOptions = $('.js-booking-option');
    var $bookingTotalPrice = $('.js-booking-total-price');
    var bookingPrice = parseFloat($('.js-booking-price').text());

    var calculateCart = function() {
      var optionsHtml = '<ul>';
      var optionsSubtotal = 0;

      $bookingOptions.each(function() {
        var $this = $(this);
        var val = parseFloat($this.val());

        if ($this.is(':checkbox')) {
          if ($this.is(':checked')) {
            val = 1;
          } else {
            val = 0;
          }
        }

        if (val === 0 || isNaN(val)) {
          $this.removeClass('active');
          $this.parent().removeClass('active');
        } else {
          // active
          var optionLabel = $this.data('label');
          var optionPrice = $this.data('price');
          var optionTotalPrice = optionPrice * val;
          optionsSubtotal += optionTotalPrice;

          $this.addClass('active');
          $this.parent().addClass('active');

          optionsHtml += '<li>';
          optionsHtml += optionLabel;
          optionsHtml += '<strong class="right">€ ' + optionTotalPrice.toString().replace('.', ',') + '</strong>';
          optionsHtml += '</li>';
        }
      });

      optionsHtml += '</ul>';
      $bookingOptionsHolder.html(optionsHtml);

      var totalPrice = bookingPrice + optionsSubtotal;
      $bookingTotalPrice.text(totalPrice.toString().replace('.', ','));
    };

    $bookingOptions.bind('input', function() {
      calculateCart();
    });
  }
};

esign.accordion = function() {
  $('.js-accordion-link').click(function(e) {
    e.preventDefault();
    $(this).toggleClass('js-accordion-link--active')
      .next('.js-accordion-content')
      .toggle('fast');
  });
};

esign.referenceForm = function() {
  $('.js-reference-toggle').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    var href = $this.attr('href');
    $(href).toggle('fast');
  });
};

esign.popover = function() {
  $('.js-popover').click(function(e) {
    e.preventDefault();
    var href = $(this).attr('href');

    if (href && href !== '') {
      var $target = $(href);
      $target.addClass('active');
      $target.find('.popover__close').focus();
    }
  });

  $('.popover__close').click(function(e) {
    $(this).parent().removeClass('active');
  });
};

esign.openingHoursPopup = function() {
  if (!esign.readCookie('popup-opening-hours')) {
    $('.modaal-opening-hours').modaal({
      overlay_opacity: 0.5,
      start_open: true
    });
    esign.createCookie('popup-opening-hours', true, 1);
  }
}

// Initialize on docready
$(esign.init);

